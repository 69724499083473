import "/src/css/app.css";

// Alpine.js
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

// Lazysizes
import 'lazysizes';

// FS Lightbox
// import '/src/fslightbox/fslightbox.js';

// TinySlider
// import '/src/tinySlider/2.9.4/tiny-slider.js';